<template>
  <div style="height: 100%;">

    <l-map ref="map" class="map" id="theMap" :zoom="karte.zoom" :center="karte.center" :options="karte.mapOptions">
      <l-control-zoom position="topright"></l-control-zoom>
      <l-tile-layer
      :name="karte.selectedTile.name"
      :url="karte.selectedTile.url"
      :attribution="karte.selectedTile.attribution"
      layer-type="base"
      />

      <v-marker-cluster ref="cluster" :options="karte.clusterOptions">
        <l-marker :id="'content-marker-'+content.id" :ref="'content-marker-'+content.id" v-for="content in contentsWithLocations" :key="content.id" :lat-lng="content.latLng" :icon="getSymbolIcon(content)" @click="highlightThisContent(content)">
          <l-popup class="institution-popup">
            <institution-marker :content="content"/>
          </l-popup>
        </l-marker>
      </v-marker-cluster>

    </l-map>
  </div>
</template>

<script>
  import 'leaflet/dist/leaflet.css';
  import { latLng, icon,latLngBounds } from 'leaflet';
  import { LMap, LTileLayer, LMarker, LPopup, LControlZoom} from "vue2-leaflet";
  import { getFieldValues } from '@/utils/helpers';
  import MarkerCluster from './markerClusterExtension';
  import InstitutionMarker from './MarkerInstitutionContent.vue';

  export default {
    name: 'MultipleMapView',
    components: {
      LTileLayer,
      LMarker,
      LPopup,
      LControlZoom,
      LMap,
      InstitutionMarker,
      'v-marker-cluster': MarkerCluster,
    },
    props: {
      height: {
        type: String,
        default: '700px',
      },
      contents: {
        type: Array,
        required: false,
        default: null,
      },
      initialCoords: {
        type: Object,
        required: false,
        default() {
          return {lat:54.35879047390532, long:10.169230974009567}
        }
      }
    },
    data() {
      return {
        L: window.L,
        karte: {
          modus: false,
          zoom: 12,
          center: latLng(this.initialCoords.lat, this.initialCoords.long),
          bounds: this.theBounds,
          mapOptions: {
            zoomSnap: 0.5,
            zoomControl: false,
            touchZoom: true,
            dragging: true,
            doubleClickZoom: true,
            scrollWheelZoom: true,
            keyboard: true,
          },
          clusterOptions: {
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
          },
          selectedTile: {
           url:"https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
           attribution:"&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors &copy; <a href=\"https://carto.com/attributions\">CARTO</a>"
         },
       },
     };
   },
   mounted() {
      this.$refs.map.fitBounds(this.markerBounds, {
        padding: [50, 50]
      });
   },
   watch: {
    contents: function(val) {
      this.$refs.map.fitBounds(this.markerBounds, {
        padding: [50, 50]
      });
    },
  },
  computed: {
    contentsWithLocations(){
      var locationContents=[];

      if(this.contents!=null){
        for(var i=0; i<this.contents.length; i++){
          this.getLatLongForContent(this.contents[i]);
          if(this.contents[i].latLng != null){
            locationContents.push(this.contents[i]);
          }
        }
      }
      return locationContents;
    },
    markerBounds(){
      var bounds = [];
      for(var i = 0; i<this.contentsWithLocations.length; i++){
        var coords = this.contentsWithLocations[i].latLng;
        bounds.push(coords);
      }
      return latLngBounds(bounds);
    }
  },
  methods: {
    zoomUpdate(zoom) {
      //console.log(zoom);
    },
    centerUpdate(center) {
      //console.log(center);
    },
    getSymbolIcon(content){
      var customIcon = null;
      if(Object.prototype.hasOwnProperty.call(content,'highlight') && content.highlight == true){
        customIcon = icon({
          iconUrl: '/assets/icons/marker.svg',
          shadowUrl: '',
          iconSize: [60, 60],/* size of the icon */
          iconAnchor: [25, 20], /* point of the icon which will correspond to marker's location */
          popupAnchor: [7, -20],
        });
        return customIcon;
      }
      else{
        customIcon = icon({
          iconUrl: '/assets/icons/marker.svg',
          shadowUrl: '',
          iconSize: [50, 50],/* size of the icon */
          iconAnchor: [20, 20], /* point of the icon which will correspond to marker's location */
          popupAnchor: [7, -20],
        })
        return customIcon;
      }
    },
    getLatLongForContent(content){
      if(content == null){
        return;
      }

      var vals = null;
      vals = getFieldValues(content,'adresse');        

      /*check if there is a latitude and a longitude*/
      if(vals!=null && Object.prototype.hasOwnProperty.call(vals,'latitude') && Object.prototype.hasOwnProperty.call(vals,'latitude')){
        content.latLng=latLng(vals.latitude,vals.longitude);
        content.lat = vals.latitude;
        content.long = vals.longitude;
        return content.latLng;
      }
      content.lat = null;
      content.long = null;
      content.latLng= null;
      return null;
    },
    highlightThisContent(content){
      this.$emit('highlight',{sender:'map', content: content});
    },
    openPopup(content){
      if('content-marker-'+content.id in this.$refs){
        this.karte.zoom=12;
        var marker = this.$refs['content-marker-'+content.id][0].mapObject;
        this.$refs['cluster'].findMarker(marker);
        marker.openPopup();
        this.karte.center = content.latLng;
      }
    }
  },
};
</script>
<style lang="scss" scoped>

  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

  .leaflet-container .leaflet-marker-pane img{
    -webkit-filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
    filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
  }

</style>

